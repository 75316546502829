<template>
  <div
    class="pl-3"
  >
    <UserHeader
      :filter="filter"
      :roles="roles"
      :active-user-count="activeUserCount"
      :pending-user-count="pendingUsersCount"
      :requested-user-count="requestedUserCount"
      :expired-user-count="expiredUserCount"
      @update-filter="updateFilter"
      @showError="showError"
      @completed="inviteCompleted"
    />
    <v-card
      class="py-6 px-6 mt-3"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <v-row
        justify="space-between"
        class="align-center"
      >
        <v-col
          cols="12"
          md="auto"
          class="d-flex align-center"
        >
          <SearchComponent 
            class="mr-3"
            @update:search="setSearchFilter" 
          />
          <ProjectUserFilter
            :available-projects="projects"
            :available-roles="roles"
            :available-tags="tags"
            @filters="handleFilters"
          />
        </v-col>
        <v-col
          cols="12"
          md="auto"
        >
          <v-row
            justify="end"
            class="align-center"
          >
            <SettingsMenu 
              table-type="users" 
            />
          </v-row>
        </v-col>
      </v-row>
      <UserTable
        :filtered-headers="filteredHeaders"
        :items="filteredItem"
        :item-key="itemKey"
        :roles="roles"
        @update-role="updateRole"
        @delete-item="onDelete"
      />
    </v-card>
    <DiscardDialog
      v-model="dialogVisible"
      :title="dialogTitle"
      @close="onDialogClose"
      @handleConfirmClick="onConfirm"
    >
      <template v-slot:content>
        <v-flex class="mt-4">
          <p class="text-start">
            {{ dialogDescription }}
          </p>
        </v-flex>
      </template>
      <template v-slot:footer>
        <v-row>
          <v-col cols="6">
            <v-btn
              dark
              large
              width="100%"
              class="text-capitalize font-weight-bold black--text mt-2"
              color="gray-100"
              @click="dialogVisible = false"
            >
              {{ $t('discard_cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              dark
              large
              width="100%"
              class="text-capitalize font-weight-bold white--text mt-2"
              color="danger"
              @click="onConfirm"
            >
              {{ $t('discard_confirm') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </DiscardDialog>
  </div>
</template>

<script>
import { mapGetters, createNamespacedHelpers, mapActions as projectMapActions } from 'vuex';
import UserHeader from '@/components/User/UserHeader';
import makeRoleService from '@/services/api/role';
import ProjectUserFilter from '@/components/Project/ProjectUserFilter.vue';
import SearchComponent from '@/components/Project/SearchComponent.vue';
import UserTable from '@/components/User/UserTable.vue';  
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import DiscardDialog from '@/components/base/DiscardDialog.vue';
import { users } from '@/constants/data.js';
import makeOrgService from '@/services/api/org';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import { handleNetworkStatusError } from '@/mixins/redirect';
import makeProjectService from '@/services/api/project';
import makeTagService from '@/services/api/tag';

const { mapActions, mapState } = createNamespacedHelpers('org');

export default {
  name: 'Users',
  components: {
    UserHeader,
    ProjectUserFilter,
    SearchComponent,
    UserTable,
    DiscardDialog,
    SettingsMenu,
  },
  mixins: [handleNetworkStatusError],
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '',
      dialogDescription: '',
      currentItem: null,
      membersState: {
        isLoading: false,
        hasError: false,
        errorMessage: '',
      },
      currentDate: new Date(),
      activeMembers: [],
      requestedUsers: [],
      activeUserCount: 0,
      users: users,
      pendingUserCount: 0,
      requestedUserCount: 0,
      items: [],
      roles: [],
      projects: [],
      tags: [],
      filter: 'active',
      itemKey: 'uid',
      searchFilter: '',
      headers: [],
      filteredItems: [], // New data property to store filtered items
    };
  },
  computed: {
    ...mapGetters(['isMenuCollapsed']),
    ...mapGetters({
      currentAccount: 'user/currentAccount',
      dynamicHeaders: 'headers/dynamicHeaders'
    }),
    ...mapGetters({
      holderAccount: 'user/user',
    }),
    ...mapState(['pendingInvites']),

    pendingUsers() {
      return this.pendingInvites.filter(item => new Date(item.expiresAt) > this.currentDate);
    },
    expiredUsers() {
      return this.pendingInvites.filter(item => new Date(item.expiresAt) < this.currentDate);
    },
    expiredUserCount() {
      return this.expiredUsers.length;
    },
    pendingUsersCount() {
      return this.pendingUsers.length;
    },
    filteredItem() {
      if (this.filteredItems.length > 0) {
        return this.filteredItems;
      }
      let filtered = this.getFilteredMembers();
      if (this.searchFilter.length > 0) {
        filtered = this.applySearchFilter(filtered);
      }
      return filtered;
    },
    filteredHeaders() {
      const filtered = this.filteredMenuHeaders.filter((header) => header.checked);
      return filtered;
    },
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.text != 'Actions');
      return filtered;
    },
    getActiveUserCount() {
      return this.activeMembers.length;
    },
  },
  created() {
    if(!this.dynamicHeaders.users) {
      this.initializeHeaders({ type: 'users' });
    }
    this.headers = this.dynamicHeaders.users;
  },
  methods: {
    ...mapActions(['getPendingInvites', 'deleteInvite', 'removeUser', 'removeUserOrg']),
    ...projectMapActions("headers", ['initializeHeaders']),
    handleFilters(filters) {
      this.applyFilters(filters);
    },

    applyFilters(filters) {
      this.filteredItems = this.getFilteredMembers().filter(user => {
        const projectMatch = filters.projects.length === 0 || 
          (user.projects && filters.projects.some(p => user.projects.includes(p.id)));
        const roleMatch = filters.roles.length === 0 || 
          (user.role && filters.roles.some(r => r.id === user.role));
        const tagMatch = filters.tags.length === 0 || 
          (user.tags && filters.tags.some(t => user.tags.includes(t.id)));
        return projectMatch && roleMatch && tagMatch;
      });
    },

    updateFilter(newFilter) {
      this.filter = newFilter;
      this.filteredItems = []; // Reset filtered items when filter changes
    },
    setSearchFilter(searchText) {
      this.searchFilter = searchText;
      this.filteredItems = []; // Reset filtered items when search changes
    },
    getFilteredMembers() {
      switch (this.filter) {
        case 'active':
          return this.activeMembers;
        case 'pending':
          return this.pendingUsers;
        case 'requested':
          return this.requestedUsers;
        default:
          return this.expiredUsers;
      }
    },
    applySearchFilter(members) {
      return members.filter((item) => this.matchesFilter(item));
    },
    matchesFilter(item) {
      const lowerCaseFilter = this.searchFilter.toLowerCase();
      const name = `${item.firstName} ${item.lastName}`;

      const nameMatch = name.toLowerCase().includes(lowerCaseFilter);

      return nameMatch;
    },

    async showError(errorMessage) {
      showErrorToast(this.$swal, 'genericError', { message: errorMessage });
    },
    async inviteCompleted() {
      try {
        showSuccessToast(this.$swal, 'inviteSuccess');
        await this.getPendingInvites(this.currentAccount.handle);
      } catch (error) {
        showErrorToast(this.$swal, 'inviteError', { error: error.message });
      }
    },
    onDelete(item) {
      this.currentItem = item;
      if (this.filter === 'active') {
        this.dialogTitle = this.$t('user_delete_title');
        this.dialogDescription = this.$t('user_delete_description');
      } else if (this.filter === 'pending') {
        this.dialogTitle = this.$t('invite_discard_title');
        this.dialogDescription = this.$t('invite_discard_description');
      } else {
        this.dialogTitle = this.$t('request_decline_title');
      }
      this.dialogVisible = true;
    },
    onDialogClose() {
      this.dialogVisible = false;
    },
    async onConfirm() {
      if (this.filter === 'active') {
        try {
          const userId = this.currentItem.uid;
          await this.deleteUserOrg(this.currentAccount.handle, userId, { userId });
          this.dialogVisible = false;
          await this.init(this.currentAccount.handle);
          showSuccessToast(this.$swal, 'deleteSuccess', { item: 'member' });
        } catch (error) {
          showErrorToast(this.$swal, 'deleteError', { item: 'member' });
        }
      } else if (this.filter === 'pending') {
        try {
          await this.deleteInvite({ handle: this.currentAccount.handle, email: this.currentItem.email });
          this.dialogVisible = false;
          await this.init(this.currentAccount.handle);
          showSuccessToast(this.$swal, 'deleteSuccess', { item: 'invite' });
        } catch (error) {
          showErrorToast(this.$swal, 'deleteError', { item: 'invite' });
        }
      }
    },
    async getMembers(per_page, next_page, handle) {
      const orgService = makeOrgService(this.$api);
      this.membersState.isLoading = true;
      try {
        const response = await orgService.getUsers(handle, { per_page: per_page, next_page: next_page });
        this.activeMembers = response.data.users;
        this.activeUserCount = this.getActiveUserCount;
        this.membersState.isLoading = false;
      } catch (error) {
        this.membersState.isLoading = false;
        this.membersState.hasError = true;
        this.membersState.errorMessage = error.message;
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'fetchError', { item: 'members' });
      }
    },
    async deleteUserOrg(handle, userId, payload) {
      const orgService = makeOrgService(this.$api);
      try {
        await orgService.removeUserOrg(handle, userId, payload);
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'deleteError', { item: 'member' });
      }
    },
    async updateRole(role, userId){
      const payload = {roleId: role.uid, members: [{
        roleId: role.currentRole,
        userId
      }] }
      const roleMembersService = makeRoleService(this.$api);
      await roleMembersService.reAssignRole(this.currentAccount.handle, role.uid, payload).then(() => {
          const findIndex = this.activeMembers.findIndex(element => element.uid == userId)
          this.activeMembers.splice(findIndex,1, {...this.activeMembers[findIndex], role })
          showSuccessToast(this.$swal, this.$t('reassignToRole', {userCount: 1, newRole: role.name}))
      }).catch(() => {
          showErrorToast(this.$swal, this.$t('unableToReassign'))   
      })
    },
    async getRoles(handle) {
      if (this.holderAccount.handle == handle) return;

      const roleService = makeRoleService(this.$api);

      try {
        const response = await roleService.getRoles(handle);
        this.roles = response.data.roles;
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'fetchError', { item: 'roles' });
      }
    },
    async init(handle) {
      await this.getMembers(30, 0, handle);
      await this.fetchProjects();
      await this.fetchRoles();
      await this.fetchTags();
      await this.getPendingInvites(handle);
    },
    async fetchProjects() {
      try {
        const projectService = makeProjectService(this.$api);
        const response = await projectService.getProjects(this.currentAccount.handle);
        this.projects = response.data.projects;
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'projects' });
      }
    },
    async fetchRoles() {
      try {
        const roleService = makeRoleService(this.$api);
        const response = await roleService.getRoles(this.currentAccount.handle);
        this.roles = response.data.roles;
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'roles' });
      }
    },
    async fetchTags() {
      try {
        const tagService = makeTagService(this.$api);
        const response = await tagService.getTags(this.currentAccount.handle);
        this.tags = response.data;
      } catch (error) {
        console.error('Error fetching tags:', error);
        showErrorToast(this.$swal, 'fetchError', { item: 'tags' });
      }
    },
  },
  async mounted() {
    await this.init(this.currentAccount.handle);
  },
  async beforeRouteUpdate(to, from, next) {
    const handle = to.params.handle;
    if (handle && handle !== from.params.handle) {
      try {
        this.activeMembers = [];
        this.roles = [];
        await this.init(handle);
        next();
      } catch (error) {
        next();
      }
    } else {
      next();
    }
  },
}
</script>